<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.installment.header') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.installment.message1')"></div>
    <br />
    <div v-html="$t('FAQ.installment.message2')"></div>
    <hr />
    <div v-html="$t('FAQ.installment.q1')"></div>
    <br />
    <div v-html="$t('FAQ.installment.a1')"></div>
    <hr />
    <div v-html="$t('FAQ.installment.q2')"></div>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/help/cicilan+step+1.png"
        alt=""
      />
    </div>
    <ol>
      <li v-html="$t('FAQ.installment.step1')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/help/cicilan+step+2.png"
        alt=""
      />
    </div>
    <ol start="2">
      <li v-html="$t('FAQ.installment.step2')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/help/cicilan+step+3.png"
        alt=""
      />
    </div>
    <ol start="3">
      <li v-html="$t('FAQ.installment.step3')"></li>
      <li v-html="$t('FAQ.installment.step4')"></li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
